html,
body {
  margin: 0;
  height: 100%;
  min-height: 100%;
  overflow: hidden;
}

.image-wrapper {
  display: flex;
  justify-content: center;
}

.image-wrapper img {
  border-radius: 8px;
}

a {
  color: inherit;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  list-style-type: none;
}

/**
 * Remove default styles
 */
button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

pre {
  text-wrap: balance;
}
